import React from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container privacy text-justify">
                <div className="mb-5 text-center">
                  <p className="privacy-heading">
                    QuickRegistry privacy statement
                  </p>
                </div>
                <div>
                  <p>
                    We at QuickRegistry (“<u>QuickRegistry,</u>” “<u>we,</u>” “
                    <u>us,</u>” or “<u>our</u>”) have created this privacy
                    policy (this “<u>Privacy Policy</u>”) because we know that
                    you care about how information you provide to us is used and
                    shared. This Privacy Policy relates to the information
                    collection and uses practices of QuickRegistry in connection
                    with our Services, which are made available to you through a
                    variety of platforms, including, but not limited to,&nbsp;
                    <Link to="/" className="custom-link">
                      www.quickregistry.in
                    </Link>
                    &nbsp; (the “<u>Website</u>
                    ”) and our mobile app, which is accessible through tablets,
                    smartphones, connected televisions, and other devices (the “
                    <u>App</u>”). The Website and the App are collectively
                    referred to as the “<u>Platform</u>.”
                    <div className="paragraph-space"></div>
                    <u>Description of Users and Acceptance of Terms</u>
                    <div className="paragraph-space"></div>
                    We welcome many users to our Website and our App, and this
                    Privacy Policy is intended to apply to all of them.
                    <div className="paragraph-space"></div>
                    Users that visit our Website (“<u>Visitors</u>”) are only
                    allowed to view publicly-available content. By visiting our
                    Website, Visitors agree to our Privacy Policy and the
                    accompanying Terms of Use.
                    <div className="paragraph-space"></div>
                    Other users sign up to access and use our Services via the
                    Platform such as by creating a gift registry for their
                    special occasion (“<u>Members</u>”). We also welcome guests,
                    who access, view and/or purchase gifts from a Member’s
                    registry (“
                    <u>Guests</u>”). By signing up, accessing, and/or using our
                    Platform, each Member and Guest thereby agrees to our
                    Privacy Policy and the accompanying Terms of Use.
                    <div className="paragraph-space"></div>
                    This Privacy policy also applies to merchants that sign up
                    to access and use our Services via the Platform such as
                    fulfilling the orders for gifts purchased by Guests from
                    Members registry (“<u>Merchants</u>”). By signing up,
                    accessing, and/or using the Platform, each Merchant hereby
                    agrees to our Privacy Policy and the accompanying Merchant
                    Agreement.
                    <div className="paragraph-space"></div>
                    Capitalized terms not defined in this Privacy Policy shall
                    have the meaning set forth in the Terms of Use (when such
                    term concerns Visitors, Members or Guests) and the Merchant
                    Agreement (when such term concerns Merchants).
                    <div className="paragraph-space"></div>
                    The Information We Collect and/or Receive
                    <div className="paragraph-space"></div>
                    In the course of operating the Platform and providing the
                    Services, QuickRegistry will collect (and/or receive) the
                    following types of information. You authorize us to collect,
                    receive, use and share such information as set forth in this
                    Privacy Policy.
                  </p>
                </div>
                <div className="number-spacing mt-4">
                  <ol>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>Personal Information</u>
                        </strong>
                        <div className="section-space"></div>
                        When you sign up to become a Member, you will be
                        required to provide us with personal information about
                        yourself, such as your first name, last name, and e-mail
                        address. If you sign up through Facebook or any other
                        social media website, you are authorizing QuickRegistry
                        to collect, store, and use, in accordance with this
                        Privacy Policy, any and all information that you agreed
                        that Facebook, Inc., or such other social media company
                        would provide to QuickRegistry through their application
                        programming interface (“<u>API</u>”). Such information
                        may include, without limitation, your first and last
                        name, social media username, social media profile
                        picture, unique social media identifier and access
                        token, and e-mail address.
                        <div className="paragraph-space"></div>
                        When a Member creates a new gift list, they may be
                        required to provide their shipping information for the
                        purpose of gift delivery. Please be aware that your
                        address will be visible to individuals who have access
                        to your gift list. Members have the option to protect
                        their gift list with a password and to control the
                        visibility of their address on the gift list. If a
                        Member chooses to make their address private, it ensures
                        that the address remains confidential. Any individual
                        attempting to purchase gifts from a list where the
                        address is not visible have the option of either
                        entering the address themselves or request the address
                        by clicking the link which will send an email request to
                        the owner of the gift list. If you sign up or add a gift
                        item via our Third-Party Partners and provide your
                        address for gift delivery, you grant us permission to
                        share your address with the Third-Party Partners.
                        <div className="paragraph-space"></div>
                        If you contact us via the Contact Us page, you will need
                        to provide your name and email address. Our Third-Party
                        Partners also provide us with personal information that
                        you have provided to such Third-Party Partners. You
                        authorize us to collect, store, and use, in accordance
                        with this Privacy Policy, any and all such personal
                        information. This Privacy Policy governs our use of this
                        personal information; it does not govern, and we have no
                        control over and will have no responsibility or
                        liability for the collection or use of your personal
                        information by the Third-Party Partners.
                        <div className="paragraph-space"></div>
                        All information that we receive under this section is
                        collectively called “<u>Personal Information</u>.” We do
                        not collect any Personal Information from Visitors,
                        Members, Guests or Merchants unless they provide such
                        information voluntarily.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>Billing Information</u>
                        </strong>
                        <div className="section-space"></div>
                        In order to process any cash gift transaction through
                        the Platform (each, a “<u>Transaction</u>”), Guests may
                        be required to provide certain billing information, such
                        as information related to their PayPal account, debit
                        card number, credit card number, expiration date, bank
                        account information, billing address, activation code,
                        and similar information (collectively, the “
                        <u>Billing Information</u>”). Such Billing Information
                        will be collected and processed by our Third-Party
                        Payment Processor pursuant to the terms and conditions
                        of their privacy policies and terms of use, and we do
                        not obtain access to any Billing Information in
                        connection with such Transactions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>User Generated Content</u>
                        </strong>
                        <div className="section-space"></div>
                        User Generated Content (UGC) is any content that is made
                        publicly available by users of a system. The systems
                        most commonly used for posting UGC are social media
                        websites, such as Facebook, Twitter, Instagram, etc. If
                        you decide to post anything on a social media platform
                        accompanied with the hashtag #ShareQuickRegistry, you
                        are thereby granting QuickRegistry.in permission to
                        copy, display, distribute, perform, publish, modify,
                        and/or translate such media in any form, as long as we
                        act in accordance with this Privacy Policy and the
                        relevant laws regarding data privacy. We may also
                        request permission to use UGC that is not accompanied
                        with the #ShareQuickRegistry hashtag, if we believe it
                        could serve a legitimate business purpose. If permission
                        is granted, you will be affording us the same rights to
                        the content as if you had originally posted it with our
                        hashtag. Generally, we will use UGC for advertising and
                        marketing purposes. We will not attempt to claim
                        ownership of any of the UGC we use, but it must be
                        understood that we will also never compensate anyone for
                        the usage of their publicly accessible data. This right
                        to use your UGC never expires, and we may also encourage
                        third parties to use or archive such content as well.
                        <div className="paragraph-space"></div>
                        Due to the fact that UGC is available to the public,
                        please be very attentive to the information you post on
                        any social media platform. Any post that is
                        discriminatory based on race, gender, sexual
                        orientation, national origin, or a mental or physical
                        disability is a violation of others' rights, and you may
                        face legal consequences as a result. Your UGC must not
                        be defamatory, sexually explicit, or used for political
                        gain, and the content you post must not result in a
                        breach of contract with us or a third party.
                        <div className="paragraph-space"></div>
                        <u>Images, Photos, and Videos</u>
                        <div className="paragraph-space"></div>
                        By using our site or app on your mobile device or any
                        other device, you grant us access to your device's
                        camera and microphone for the purpose of capturing
                        images or taking videos as part of the functionality and
                        features we offer. We will only access your device's
                        camera and microphone with your explicit permission.
                        This access will be limited to the duration of your
                        active use of our services and will be utilized for
                        purposes, including but not limited to uploading custom
                        images or photos of gifts on the registry, scanning
                        barcodes, and personalizing the registry landing page.
                        Please keep in mind that if you do not grant consent for
                        the collection and use of images or photos, certain
                        functions or features of our services may not be
                        available to you.
                        <div className="paragraph-space"></div>
                        Additionally, please note that by using our services,
                        you acknowledge and consent to the collection, use, and
                        potential disclosure of images, photos, and videos that
                        you voluntarily share with us. We may utilize these
                        images or photos to enhance our services, including but
                        not limited to the features mentioned above. We are
                        committed to securely storing and protecting your
                        personal information and employ all reasonable measures
                        to safeguard against unauthorized access, disclosure,
                        alteration, or destruction. Please note that we do not
                        sell, trade, or disclose your images or photos to third
                        parties without your explicit consent, except as
                        required by law or to provide our services.
                        <div className="paragraph-space"></div>
                        Please be aware that if your registry is not
                        password-protected and searchable, any images, photos,
                        or videos you voluntarily share may become publicly
                        available. It is crucial to exercise caution and
                        discretion when uploading information. Posting content
                        that is discriminatory based on race, gender, sexual
                        orientation, national origin, or a mental or physical
                        disability violates the rights of others and may result
                        in legal consequences. Moreover, it is important not to
                        post defamatory, sexually explicit, or politically
                        motivated content.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>Other Information</u>
                        </strong>
                        <div className="section-space"></div>
                        In addition to the Personal Information, we may collect
                        or receive additional information (collectively, the “
                        <u>Other Information</u>”). Such Other Information may
                        include:
                        <div className="paragraph-space"></div>
                        <ul style={{ listStyleType: "lower-alpha" }}>
                          <li>
                            <p>
                              <strong>
                                <u>From Your Activity</u>:
                              </strong>
                              &nbsp; Information that we automatically collect
                              when you use the Platform, such as the device from
                              which you access the Platform and/or the Services,
                              your IP addresses, browser type and language,
                              referring and exit pages and URLs, date and time,
                              the amount of time spent on particular pages, what
                              sections of the Platform you visit, and similar
                              information concerning your use of the Platform
                              and/or the Services.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                <u>From Cookies</u>:
                              </strong>
                              &nbsp; Information that we collect using “cookie”
                              technology. Cookies are small packets of data that
                              a website stores on your computer’s hard drive so
                              that your computer will “remember” information
                              about your visit. We use cookies to help us
                              collect Other Information and to enhance your
                              experience using the Platform. We provide you
                              notice of such cookie collection upon accessing
                              our Platform. If you do not want us to deploy
                              cookies in your browser, you can opt out by
                              setting your browser to reject cookies or to
                              notify you when a website tries to put a cookie in
                              your browser software. If you choose to disable
                              cookies in your browser, you can still use the
                              Platform, although your ability to use some of the
                              features may be affected.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                <u>Third-Party Analytics</u>:
                              </strong>
                              &nbsp; We use third-party analytics services (such
                              as Google Analytics) to evaluate your use of the
                              Platform, compile reports on activity, collect
                              demographic data, analyze performance metrics, and
                              collect and evaluate other information relating to
                              the Platform and Internet usage. These third
                              parties use cookies and other technologies to help
                              analyze and provide us the data. By accessing and
                              using the Platform, you consent to the processing
                              of data about you by these analytics providers in
                              the manner and for the purposes set out in this
                              Privacy Policy. For more information on these
                              third parties, including how to opt out from
                              certain data collection, please visit the sites
                              below. Please be advised that if you opt out of
                              any service, you may not be able to use the full
                              functionality of the Platform.
                            </p>
                          </li>
                        </ul>
                        For Google Analytics, please visit&nbsp;
                        <Link
                          to="https://www.google.com/analytics"
                          className="custom-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://www.google.com/analytics
                        </Link>
                      </p>
                    </li>
                  </ol>
                </div>

                <p>
                  <u>Third-Party Partners</u>
                  <div className="section-space"></div>
                  QuickRegistry.in expects all partners and any third parties we
                  conduct business with to comply with relevant data protection
                  and privacy laws at all times. We take every commercially
                  reasonable step to ensure consistent compliance on our end.
                  Transparency is becoming more of a focus for lawmakers when
                  drafting new data privacy laws, so we have made it a priority
                  to ensure all users of our site have the ability to find out
                  how their data is being used, for what purpose, and for how
                  long. We expect the same from all of our partners and any
                  third parties, and will do our best to ensure that we do not
                  conduct business with any individual person or entity that is
                  not compliant with all relevant laws. If any user wishes to
                  receive information pertaining to the use or collection of
                  their personal data at any time, we expect all of our partners
                  to be able to provide that information as necessary. If there
                  is a breach of contract due to the failure of a third party to
                  produce information, an injunction may be sought against that
                  third party to suspend their business operations until they
                  become compliant, and monetary damages may be sought for the
                  actual breach.
                </p>

                <p>
                  <u>
                    The Information Collected by or Through Third-Party
                    Advertising Companies
                  </u>
                  <div className="section-space"></div>
                  You authorize us to share Other Information about your
                  activity on the Platform with third parties for the purpose of
                  tailoring, analyzing, managing, reporting, and optimizing
                  advertising you see on the Platform, and elsewhere. These
                  third parties may use cookies, pixel tags (also called web
                  beacons or clear gifs), and/or other technologies to collect
                  such Other Information for such purposes. Pixel tags enable
                  us, and these third-party advertisers, to recognize a
                  browser’s cookie when a browser visits the site on which the
                  pixel tag is located in order to learn which advertisement
                  brings a user to a given site. You may opt-out of the
                  tailoring of advertising based on information we collect. To
                  learn more about the use of this information, or to choose not
                  to have this information used by our providers or third party
                  advertising partners by opting out, please visit the Network
                  Advertising Initiative by clicking&nbsp;
                  <Link
                    to="http://www.networkadvertising.org/managing/opt_out.asp"
                    className="custom-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.networkadvertising.org/managing/opt_out.asp
                  </Link>
                  .
                </p>

                <p>
                  <u>
                    Information Collected through the Platform That IS NOT
                    Covered by this Privacy Policy
                  </u>
                  <div className="section-space"></div>
                  Members may post information using blogs, forums and other
                  similar features available through the Platform. NONE of the
                  information you provide using these features is protected by
                  this Privacy Policy. THIS PRIVACY POLICY DOES NOT APPLY TO ANY
                  INFORMATION (INCLUDING PERSONAL INFORMATION) THAT YOU PROVIDE
                  USING BLOGS, FORUMS OR OTHER SIMILAR FEATURES AVAILABLE
                  THROUGH THE PLATFORM. ALL SUCH INFORMATION IS PUBLIC
                  INFORMATION AND MAY BE SEEN, COLLECTED OR USED BY ANY USER OF
                  THE PLATFORM.
                </p>

                <p>
                  <u>How We Use and Share the Information</u>
                  <div className="section-space"></div>
                  You authorize us to use the Personal Information, and the
                  Other Information (collectively, the “Information”) to provide
                  and improve the Platform; to solicit your feedback; and inform
                  you about our products and services and those of our
                  promotional partners.
                </p>

                <p>
                  You also authorize us to use and/or share Information as
                  described below.
                </p>
                <ul>
                  <li>
                    <p>
                      <u>Agents, Providers and Related Third Parties:</u> We may
                      engage other companies and individuals to perform certain
                      business-related functions on our behalf. Examples may
                      include providing technical assistance, order fulfillment,
                      customer service, and marketing assistance. These other
                      companies will have access to the Information only as
                      necessary to perform their functions and to the extent
                      permitted by law. We may also share your Information with
                      any of our parent companies, subsidiaries, or other
                      companies under common control with us. Any personal
                      information collected on our behalf is not later sold or
                      used by any agents, providers, or related third parties.
                    </p>
                  </li>
                  <div className="paragraph-space"></div>
                  <li>
                    <p>
                      <u>Aggregated Information:</u> In an ongoing effort to
                      better understand our Visitors, Members, Guests, and
                      Merchants, we may analyze Personal Information with Other
                      Information in anonymized and aggregate form in order to
                      operate, maintain, manage, and improve the Platform. This
                      aggregate information does not identify you personally. We
                      may share this aggregate data with our affiliates, agents,
                      business and promotional partners, and other third
                      parties. We may also disclose aggregated user statistics
                      in order to describe the Platform to current and
                      prospective business partners and to other third parties
                      for other lawful purposes.
                    </p>
                  </li>
                  <div className="paragraph-space"></div>
                  <li>
                    <p>
                      <u>Business Transfers:</u> As we develop our businesses,
                      we might sell or buy businesses or assets. In the event of
                      a corporate sale, merger, reorganization, sale of assets
                      dissolution, or similar event, the Information may be part
                      of the transferred assets.
                    </p>
                  </li>
                  <div className="paragraph-space"></div>
                  <li>
                    <p>
                      <u>Legal Requirements:</u> We may disclose your
                      Information if required to do so by law or in the good
                      faith belief that such action or disclosure is necessary
                      or appropriate to: (i) operate the Platform, (ii) comply
                      with any legal obligation, report unlawful activity,
                      cooperate with law enforcement, or protect against legal
                      liability, (iii) protect and defend our rights, property,
                      personnel, suppliers, sponsors, agents, or licensors, or
                      (iv) protect the personal safety of vendors, users of the
                      Platform, or the public.
                    </p>
                  </li>
                  <div className="paragraph-space"></div>
                  <li>
                    <p>
                      <u>Third Party Partners:</u> We may disclose your Personal
                      Information to our Third Party Partners to whom you
                      provided your information as a part of the sign-up process
                      for the Platform. Such Third Party Partners and the
                      company who operates the Third Party Partner website may
                      use your Personal Information for their own marketing and
                      business purposes.
                    </p>
                  </li>
                  <div className="paragraph-space"></div>
                  <li>
                    <p>
                      <u>Third Parties Marketing Companies:</u> With your
                      permission, we may disclose your Personal Information to
                      third party marketers (who may combine such Personal
                      Information with their own records and records available
                      from other sources) for their own direct marketing
                      purposes. If you opt-in to this information sharing and
                      later want us to stop sharing your Personal Information
                      with such third party marketers, please send an email with
                      your name and address to&nbsp;
                      <Link
                        to="mailto:info@quickregistry.in"
                        className="custom-link"
                      >
                        info@quickregistry.in
                      </Link>
                      .
                    </p>
                  </li>
                </ul>

                <p>
                  <u>
                    Accessing and Modifying Information and Communication
                    Preferences
                  </u>
                  <div className="section-space"></div>
                  Members and Merchants may access, remove, review, and/or make
                  changes to their Personal Information by emailing us at&nbsp;
                  <Link
                    to="mailto:info@quickregistry.in"
                    className="custom-link"
                  >
                    info@quickregistry.in
                  </Link>
                  . In addition, you may manage your receipt of marketing and
                  non-transactional communications by clicking on the
                  “unsubscribe” link located on the bottom of any QuickRegistry
                  marketing e-mail. We will use commercially reasonable efforts
                  to process such requests in a timely manner. You should be
                  aware, however, that it is not always possible to completely
                  remove or modify information in our subscription databases.
                  Members cannot opt out of receiving transactional emails
                  related to their account with QuickRegistry.
                </p>

                <p>
                  <u>How We Protect the Information</u>
                  <div className="section-space"></div>
                  We take commercially reasonable steps to protect the
                  Information from loss, misuse, and unauthorized access,
                  disclosure, alteration, or destruction. Please understand,
                  however, that no security system is impenetrable. We cannot
                  guarantee the security of our databases or the databases of
                  the third parties with which we may share such Information,
                  nor can we guarantee that the Information you supply will not
                  be intercepted while being transmitted over the Internet. In
                  particular, email sent to us may not be secure, and you should
                  therefore take special care in deciding what information you
                  send to us via email.
                </p>

                <p>
                  <u>External Websites</u>
                  <div className="section-space"></div>
                  The Platform may contain links to third-party websites.
                  QuickRegistry has no control over the privacy practices or the
                  content of these websites. As such, we are not responsible for
                  the content or the privacy policies of those third-party
                  websites. You should check the applicable third-party privacy
                  policy and terms of use when visiting any other websites.
                </p>

                <p>
                  <u>Children</u>
                  <div className="section-space"></div>
                  We do not knowingly collect Personal Information from children
                  under the age of 13 through the Platform. If you are under 13,
                  please do not give us any Personal Information. We encourage
                  parents and legal guardians to monitor their children’s
                  Internet usage and to help enforce our Privacy Policy by
                  instructing their children never to provide Personal
                  Information without their permission. If you have reason to
                  believe that a child under the age of 13 has provided Personal
                  Information to us, please contact us, and we will endeavor to
                  delete that information from our databases.
                </p>

                <p>
                  <u>Important Notice to Non-Indian Residents</u>
                  <div className="section-space"></div>
                  The Platform and the Services are operated in the India. If
                  you are located outside of the India, please be aware that any
                  information you provide to us will be transferred to the
                  India. By visiting the Website and/or accessing and/or using
                  the Platform and/or the Services and/or providing us with any
                  information, you consent to this transfer.
                  <div className="paragraph-space"></div>
                  As a resident of any state, regardless of whether specific
                  privacy laws apply to our company, we understand the
                  importance of providing individuals with certain rights and
                  choices regarding their personal information. We are committed
                  to addressing your requests related to access, deletion,
                  correction, portability, or restriction of processing of your
                  personal information. These requests are not a legal right,
                  but we are granting them to you in respect of your privacy
                  rights. You may also designate an authorized agent to exercise
                  these requests on your behalf. We will make reasonable efforts
                  to respond to your requests within the timeframes. Please note
                  that certain requests may be subject to legal limitations or
                  exceptions, and we reserve the right to verify your identity
                  before processing your requests.
                </p>

                <p>
                  <u>Sources of Personal Information</u>
                  <div className="section-space"></div>
                  We collect personal information directly from you, including
                  when you register for an account and create a profile,
                  subscribe for newsletters and updates, purchase products as a
                  member or as a guest, or sign up for and participate in any
                  contests, sweepstakes, and promotions we provide.
                </p>

                <p>
                  <u>Business Use and Purpose of Personal Information</u>
                  <div className="section-space"></div>
                  We collect your personal information for business and
                  commercial purposes, including to provide and improve our
                  services, complete the transactions you request, create and
                  manage your account, and respond to your requests and other
                  customer service communications.
                </p>
                <p>
                  <u>Submitting Requests</u>
                  <div className="section-space"></div>
                  Sending an email to&nbsp;
                  <Link
                    to="mailto:privacy@quickregistry.in"
                    className="custom-link"
                  >
                    privacy@quickregistry.in
                  </Link>
                  &nbsp; titled “Privacy Request,” indicating the request and
                  specifying whether the purpose of such request is to know what
                  personal information we have or to delete such personal
                  information. You must include your first name, last name, and
                  email address and specify the type of request you desire. We
                  cannot start the response procedure if we do not have all of
                  this information. Upon submission of the email, you will
                  receive a receipt of notice that will prompt you to verify
                  your email. To protect your privacy, we are required by law to
                  first verify your identity prior to granting access to your
                  data.
                </p>

                <p>
                  <u>Sensitive Information</u>
                  <div className="section-space"></div>
                  We do not usually seek to collect your sensitive personal
                  information (i.e., data relating to your social security,
                  driver’s license, state identification card, or passport
                  numbers; non-QuickRegistry account username or number or
                  financial account, debit card, or credit card number in
                  combination with credentials allowing access to such accounts;
                  racial or ethnic origin; immigration or citizenship status;
                  religious or philosophical beliefs; trade union membership;
                  genetic or biometric data for identification purposes; precise
                  geolocation; political opinions; medical or health conditions;
                  or sex life or sexual orientation. Sensitive personal
                  information also includes the contents of your personal mail,
                  email, or text messages unless we are the intended recipient).
                  We will obtain your explicit consent to collect and use such
                  information where required by law.
                </p>

                <p>
                  <u>Minors’ Privacy</u>
                  <div className="section-space"></div>
                  Our services are not intended for children under the age of
                  18. QuickRegistry does not target our services to children
                  under 18. To request removal of content or information, please
                  send an email or contact our Customer care team.
                  <div className="paragraph-space"></div>
                  In addition, QuickRegistry does not monitor, recognize, or
                  honor any opt-out or do not track mechanisms, including
                  general web browser “Do Not Track” settings and/or signals.
                </p>

                <p>
                  <u>For Residents of the European Union</u>
                </p>
                <p>
                  <u>European Privacy Rights</u>
                  <div className="section-space"></div>
                  For those within the European Economic Area (EEA), the General
                  Data Protection Regulation (GDPR) that went into effect on May
                  25, 2018 has afforded you certain rights to your personal
                  data. These rights are as follows: (a) the right to access any
                  personal information being held about you, (b) the right to
                  have any personal information being held about you permanently
                  deleted (provided that the information is not required to
                  comply with a legal obligation or to exercise a legal claim),
                  (c) the right to rectify any inaccurate personal information
                  without undue delay, (d) the right to restrict processing of
                  your personal information (provided that processing is not
                  required to comply with a legal obligation or exercise a legal
                  claim), (e) the right to request transfer of your personal
                  information directly to a third party if technically feasible,
                  and (f) the right to object to processing of any personal data
                  at any time.
                </p>

                <p>
                  <u>Right to Object to Direct Marketing</u>
                  <div className="section-space"></div>
                  All users can object to the processing of personal information
                  for the purposes of direct marketing at any time, free of
                  charge.
                  <div className="paragraph-space"></div>
                  If you feel that we are not adhering to the GDPR or our
                  Privacy Policy at any time, you have the right to file a
                  complaint with an EU Data Protection Authority.
                </p>

                <p>
                  <u>Use of Collected Information</u>
                  <div className="section-space"></div>
                  QuickRegistry.in will only use the information collected from
                  and about you on the following lawful grounds:
                </p>
                <ul style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    <p>
                      Use is necessary to perform a contract we have entered
                      into or are about to enter into with you;
                    </p>
                  </li>
                  <div className="section-space"></div>
                  <li>
                    <p>
                      Use is necessary for our legitimate business interests;
                    </p>
                  </li>
                  <div className="section-space"></div>
                  <li>
                    <p>
                      Compliance with a legal or regulatory obligation is
                      required; or
                    </p>
                  </li>
                  <div className="section-space"></div>
                  <li>
                    <p>
                      Where consent has been provided (which can be withdrawn at
                      any time)
                    </p>
                  </li>
                </ul>

                <p>
                  <u>Additional Information</u>
                  <div className="section-space"></div>
                  If none of these conditions will be satisfied by the use of
                  your personal information, then such information will be
                  permanently deleted from our system accordingly.
                  <div className="paragraph-space"></div>
                  In addition to the personal information you provide to us, we
                  may also use publicly available information as well as
                  information gathered from you by third parties to improve your
                  shopping experience, inform you about new products or
                  promotions, or for any other legitimate business purpose.
                </p>

                <p>
                  <u>Right to Erasure</u>
                  <div className="section-space"></div>
                  All users of QuickRegistry.in have the right to request that
                  any and all Personally Identifiable Information (PII)
                  associated with their account be erased. Once a data subject
                  access request (DSAR) is filed and customer identity is
                  verified, the Company must respond to and fulfill such request
                  within 30 days. When an account is “erased,” all PII
                  associated with the account will be permanently deleted,
                  leaving the existence of an anonymous account solely for the
                  purpose of maintaining proper transactional records. The right
                  to erasure strictly pertains to PII, and it must be explicitly
                  understood that transactional history will not be deleted from
                  the Company’s records for any reason. The right to erasure
                  exists to protect users of websites from having their PII
                  stored, transferred, or otherwise utilized by another entity
                  in any manner without proper consent. It will not extend so
                  far as to limit the financial responsibilities and legal
                  obligations that QuickRegistry.in has to its customers,
                  employees, partners, and the authorities.
                </p>

                <p>
                  <u>Third-Party Partners</u>
                  <div className="section-space"></div>
                  When QuickRegistry.in members sign up through one of our
                  software partners, we begin sharing customer data with that
                  partner. While we are directly responsible for our handling of
                  your information, users must be aware that our partners also
                  store customer information for their own business purposes.
                  Therefore, when a member desires to invoke their right to
                  erasure, the member must send deletion requests to any partner
                  websites they signed up through as well as to QuickRegistry.in
                  to have the totality of their data erased.
                  <div className="paragraph-space"></div>
                  Members or guests of QuickRegistry.in who elect to have their
                  PII deleted from our system may do so by contacting&nbsp;
                  <Link
                    to="mailto:privacy@quickregistry.in"
                    className="custom-link"
                  >
                    privacy@quickregistry.in
                  </Link>
                  .
                </p>

                <p>
                  <u>Right to Access</u>
                  <div className="section-space"></div>
                  All users of QuickRegistry.in have the undeniable right to ask
                  about and obtain a complete and accurate report on the data
                  being stored about them. If you would like to request a report
                  on the data we are storing about you, email us at&nbsp;
                  <Link
                    to="mailto:privacy@quickregistry.in"
                    className="custom-link"
                  >
                    privacy@quickregistry.in
                  </Link>
                  .
                </p>
                <p>
                  <u>Transmitting PII + Responding to DSARs</u>
                  <div className="section-space"></div>
                  <ol>
                    <li>
                      <p>
                        A DSAR (Data Subject Access Request) must be sent by the
                        user whose information is being requested to&nbsp;
                        <Link
                          to="mailto:privacy@quickregistry.in"
                          className="custom-link"
                        >
                          privacy@quickregistry.in
                        </Link>
                        .
                      </p>
                    </li>
                    <li>
                      <p>
                        The user’s identity must be verified by the submission
                        of a photo ID that clearly shows the user’s legal name.
                        (This is so we can match the name on the photo ID with
                        the name on the QuickRegistry.in account.)
                      </p>
                    </li>
                    <li>
                      <p>
                        QuickRegistry.in will send the user an encrypted,
                        password-protected email containing the PII requested.
                      </p>
                    </li>
                    <li>
                      <p>
                        QuickRegistry.in will send the user a separate email
                        containing the password that unlocks the encrypted PII in
                        the email.
                      </p>
                    </li>
                  </ol>
                </p>

                <p>
                  <u>Option to Password-Protect Your Account</u>
                  <div className="section-space"></div>
                  If you create a wedding registry with us, you consent to us
                  posting the names of both yourself and any other name that you
                  provided on our website. This information may unwittingly
                  reveal your sexual orientation to website visitors. If you
                  wish to limit access to this information, you have the option
                  to password-protect your account so that only individuals with
                  your password will be able to view your wedding registry. You
                  can find this option under the "Visitor setup" section in our
                  "Settings" menu.
                </p>

                <p>
                  <u>Changes to This Privacy Policy</u>
                  <div className="section-space"></div>
                  This Privacy Policy is effective as of the date stated at the
                  bottom of this Privacy Policy. We may change this Privacy
                  Policy from time to time. You can know if this privacy policy
                  has changed since the last time you viewed it by checking it
                  on a regular basis. If we make a material change to the
                  Policy, you will be provided with appropriate notice in
                  accordance with legal requirements. By accessing the Website
                  and/or using the Platform after we make any such changes to
                  this Privacy Policy, you are deemed to have accepted such
                  changes. Please be aware that, to the extent permitted by
                  applicable law, our use of the Information is governed by the
                  Privacy Policy in effect at the time we collect the
                  Information.
                </p>

                <p>
                  <u>How to Contact Us</u>
                  <div className="section-space"></div>
                  If you have questions about this Privacy Policy, please
                  contact QuickRegistry via email at&nbsp;
                  <Link
                    to="mailto:customercare@quickregistry.in"
                    className="custom-link"
                  >
                    customercare@quickregistry.in
                  </Link>
                  &nbsp; with “Privacy Policy” in the subject line.
                </p>

                <p>
                  This Privacy Statement is effective May 06, 2024.
                  <div className="paragraph-space"></div>
                  <b>Last Updated:</b> 06/05/2024
                </p>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
