import Cookies from 'js-cookie';

const getRegistryCredentials = () => {
  const fromMobileApp = localStorage.getItem("fromMobileApp");

  const getItem = (key) => {
    if (fromMobileApp) {
      return Cookies.get(key) || localStorage.getItem(key);
    }
    return Cookies.get(key);
  };

  const rawHTML = localStorage.getItem("rawHTML");
  const productUrl = localStorage.getItem("productUrl");
  const trimmedUrl = localStorage.getItem("trimmedUrl");
  const fromiOSDevice = localStorage.getItem("fromiOSDevice");
  
  const accessToken = getItem("accessToken");
  const registryId = getItem("registryId");
  const userId = getItem("userId");
  const userName = getItem("userName");
  const userEmail = getItem("userEmail");
  const jsonWebToken = getItem("jsonWebToken");
  const guestbookSigned = getItem("guestbookSigned");
  const registryTypeId = getItem("registryTypeId");
  const registryTypeName = getItem("registryTypeName");
  const registryPublic = getItem("registryPublic");

  return { rawHTML, productUrl, trimmedUrl, fromMobileApp, fromiOSDevice, accessToken, registryId, userId, userEmail, jsonWebToken, userName, guestbookSigned, registryTypeId, registryTypeName, registryPublic };
};

const clearRegistryCredentials = () => {
  const keys = [
    "accessToken", "registryId", "userId", "userName", "userEmail",
    "jsonWebToken", "guestbookSigned", "registryTypeId", "registryTypeName", "registryPublic"
  ];

  keys.forEach(key => Cookies.remove(key, { sameSite: "None", secure: true }));

  const retainedItems = ['fromMobileApp', 'fromiOSDevice'].reduce((items, key) => {
    const value = localStorage.getItem(key);
    if (value !== null) items[key] = value;
    return items;
  }, {});

  localStorage.clear();

  Object.entries(retainedItems).forEach(([key, value]) => localStorage.setItem(key, value));
};

export { getRegistryCredentials, clearRegistryCredentials };
