import React from 'react';
import { Link } from 'react-router-dom';
import { getRegistryCredentials } from '../../utils/registryUtils';

const Footer = () => {
  const { fromMobileApp } = getRegistryCredentials();

  const scrollToGuides = () => {
    const guideSection = document.getElementById("baby-guides");
    if (guideSection) {
      const yOffset = guideSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const scrollToFaqs = () => {
    const faqSection = document.getElementById("faqs");
    if (faqSection) {
      const yOffset = faqSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  };
  
  if (fromMobileApp) {
    return (
      <footer className="footer mobile-footer">
        <div className="copyright">
            <p>
              Made in India 🇮🇳 with ❤️
              <br/>
              © 2024 All rights reserved - QuickRegistry
            </p>
        </div> 
      </footer>
    );
  }

  return (
    <footer className="footer" data-aos="fade-up" data-aos-delay="100">
      <div className="container">
      <div className="footer-inner">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="footer-list">
                <h4>Baby Registry</h4>
                <ul>
                  <li><Link to="/signup" onClick={scrollToTop}>Create a Babylist</Link></li>
                  <li><Link to="/search" onClick={scrollToTop}>Find a babylist</Link></li>
                  <li><Link to="#" onClick={scrollToGuides}>Baby Guides</Link></li>
                  <li><Link to="/baby-checklist" onClick={scrollToTop}>Baby Registry Checklist</Link></li>
                  <li><Link to="/add-gifts" onClick={scrollToTop}>How to add Gifts</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="footer-list">
                <h4>Support</h4>
                <ul>
                  <li><Link to="/feedback" onClick={scrollToTop}>Feedback Form</Link></li>
                  <li><Link to="/advertise-with-us" onClick={scrollToTop}>Patner with US</Link></li>
                  <li><Link to="/terms&conditions" onClick={scrollToTop}>Terms and Conditions</Link></li>
                  <li><Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link></li>
                  <li><Link to="#" onClick={scrollToFaqs}>FAQs</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="footer-list">
                <h4 className="heading-app-links">Download the <br/> QuickRegistry.in App</h4>
                <div className="app-links">
                  <Link to="https://apps.apple.com/in/app/quickregistry/id6502443828?platform=iphone" target="_blank">
                    <img src="/assets/images/app-store.png" alt="App Store" className="app-download-image"/>
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=in.quickregistry.app" target="_blank">
                    <img src="/assets/images/google-play.png" alt="Google Play" className="app-download-image"/>
                  </Link>
                </div>
                <br/><br/>
                <h4 className="heading-app-links">Connect with us</h4>
                <ul className="big-social-icons">
                  <li><Link to="#"><i className="la la-facebook"></i></Link></li>
                  <li><Link to="#"><i className="la la-instagram"></i></Link></li>
                  <li><Link to="#"><i className="la la-twitter"></i></Link></li>
                  <li><Link to="#"><i className="la la-linkedin"></i></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="row">
            <div className="col-md-6">
            <p>
              Made in India <img src="/assets/images/icon/india-flag-icon.svg" alt="India Flag" style={{ width: "17px" }} /> with ❤️
            </p>
            </div>
            <div className="col-md-6">
              <div className="copyright-line">
                <p>© 2024 All rights reserved - QuickRegistry</p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
