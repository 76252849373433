export const getRegistryTypeFormat = (registryTypeId, dateAnswer) => {
  const today = new Date();
  const isSameDate = (date1, date2) => date1.toDateString() === date2.toDateString();
  const isFutureDate = (date) => new Date(date) > today;

  if (registryTypeId === "1") {
    if (isFutureDate(dateAnswer) || isSameDate(new Date(dateAnswer), today)) {
      return `Baby's arrival date: ${formatAnswerDate(dateAnswer)}`;
    } else {
      return `Baby's Birthday: ${formatAnswerDate(dateAnswer)}`;
    }
  } else if (registryTypeId === "2") {
    if (isSameDate(new Date(dateAnswer), today)) {
      return `You're getting married today!`;
    } else if (isFutureDate(dateAnswer)) {
      const daysDifference = (date) => Math.ceil((new Date(date) - today) / (1000 * 60 * 60 * 24));
      const daysUntilDate = daysDifference(dateAnswer);
      return `You're getting married in ${daysUntilDate} day${daysUntilDate > 1 ? "s" : "" }`;
    } else {
      return `Married Since: ${formatAnswerDate(dateAnswer)}`;
    }
  } else {
    return "EVENT DATE";
  }
};

const formatAnswerDate = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return "";
  }
  const options = { month: "long", day: "numeric", year: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};
