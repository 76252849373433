import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from "../../utils/toastUtils";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { getRegistryCredentials } from "../../utils/registryUtils";
import api from "../../services/api";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  const [isRetypePasswordValid, setIsRetypePasswordValid] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [resetEmailAddress, setResetEmailAddress] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [remainingTime, setRemainingTime] = useState(60);
  const [requestLoading, setRequestLoading] = useState(false);
  const { fromMobileApp } = getRegistryCredentials();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (step === 2) {
    const inputs = document.querySelectorAll(".otp-field > input");
    const button = document.querySelector(".reset-btn");

    window.addEventListener("load", () => inputs[0].focus());
    button.setAttribute("disabled", "disabled");

    inputs[0].addEventListener("paste", function (event) {
      event.preventDefault();

      const pastedValue = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      const otpLength = inputs.length;

      for (let i = 0; i < otpLength; i++) {
        if (i < pastedValue.length) {
          inputs[i].value = pastedValue[i];
          inputs[i].removeAttribute("disabled");
          inputs[i].focus();
        } else {
          inputs[i].value = "";
          inputs[i].focus();
        }
      }
    });

    inputs.forEach((input, index1) => {
      input.addEventListener("keyup", (e) => {
        const currentInput = input;
        const nextInput = input.nextElementSibling;
        const prevInput = input.previousElementSibling;

        if (currentInput.value.length > 1) {
          currentInput.value = "";
          return;
        }

        if (
          nextInput &&
          nextInput.hasAttribute("disabled") &&
          currentInput.value !== ""
        ) {
          nextInput.removeAttribute("disabled");
          nextInput.focus();
        }

        if (e.key === "Backspace") {
          inputs.forEach((input, index2) => {
            if (index1 <= index2 && prevInput) {
              input.setAttribute("disabled", true);
              input.value = "";
              prevInput.focus();
            }
          });
        }

        button.classList.remove("active");
        button.setAttribute("disabled", "disabled");

        const inputsNo = inputs.length;
        if (
          !inputs[inputsNo - 1].disabled &&
          inputs[inputsNo - 1].value !== ""
        ) {
          button.classList.add("active");
          button.removeAttribute("disabled");

          return;
         }
       });
     });
   }
 }, [step]);

 useEffect(() => {
  if (step === 2 && remainingTime > 0) {
    let timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }
}, [step, remainingTime]);

const toggleNewPasswordVisibility = () => {
  setShowNewPassword(!showNewPassword);
};

const toggleRetypePasswordVisibility = () => {
  setShowRetypePassword(!showRetypePassword);
};

const handleEmailChange = (e) => {
  setEmail(e.target.value);
  setIsEmailValid(true);
};

const handleNewPasswordChange = (e) => {
  setNewPassword(e.target.value);
  setIsNewPasswordValid(true);
}

const handleRetypePasswordChange  = (e) => {
  setRetypePassword(e.target.value);
  setIsRetypePasswordValid(true);
}

const handleRecaptchaChange = (value) => {
  setRecaptchaValue(value);
};

const handleEmailSubmit = async (e) => {
  e.preventDefault();

    if (!email.trim()) {
    showToast('Please enter the registered email');
    setIsEmailValid(false);
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    showToast('Please enter a valid email');
    setIsEmailValid(false);
    return;
  }

  if (!fromMobileApp && !recaptchaValue) {
    showToast('Please confirm that you are not a robot');
    return;
  }

  try {
    setLoading(true);
    await api.forgotPassword(email);
    setResetEmailAddress(email);
    setStep(2);
    } catch (error) {
      if (error.response?.data?.error === "User not found with the provided email.") {
        showToast("User not found. Please try again");
      } else {
        showToast('Error. Please try again', "error");
      }
    } finally {
    setLoading(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();

    const inputs = document.querySelectorAll(".otp-field > input");
    let enteredOTP = '';
  
    inputs.forEach((input) => {
      enteredOTP += input.value;
    });
  
    try {
      setLoading(true);
      await api.validateOTP(enteredOTP, email);
      setVerificationCode(enteredOTP);
  
      setStep(3);
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error === "Invalid credentials") {
          showToast("Invalid OTP. Please try again.");
        } else if (error.response.data.error === "OTP expired. Please generate OTP again") {
          showToast("OTP expired. Please request again.");
        } else {
          showToast("Failed to validate OTP. Please try again.");
        }
      } else {
        showToast("Failed to validate OTP. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRequestAgain = async () => {
    try {
      setRequestLoading(true);
      await api.forgotPassword(resetEmailAddress);
      showToast('Verification code sent again successfully', "success");
      setRemainingTime(60);
    } catch (error) {
      console.error(error.message);
      showToast('Error resending verification code', "error");
    } finally {
      setRequestLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!newPassword.trim()) {
      showToast('Please enter your new password');
      setIsNewPasswordValid(false);
      return;
    }
  
    if (!retypePassword.trim()) {
      showToast('Please retype your new password');
      setIsRetypePasswordValid(false);
      return;
    }
  
    if (newPassword.length < 8 || newPassword.length > 20) {
      showToast('Password must be between 8 and 20 characters');
      setIsNewPasswordValid(false);
      return;
    }
  
    if (newPassword !== retypePassword) {
      showToast('Passwords must match');
      setIsNewPasswordValid(false);
      setIsRetypePasswordValid(false);
      return;
    }

    try {
      setLoading(true);
      await api.setNewPassword(verificationCode, newPassword, retypePassword);
      setVerificationCode('');
      setResetEmailAddress('');

      navigate('/');
      showToast('Password reset successfully', "success");
    } catch (error) {
      console.error(error.message);
      showToast('An error occurred. Please try again', "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashboard">
                      <div className="user-dashboard">
                        <div className="reset-password text-center">
                        {step === 1 && (
                          <>
                          <div className="reset-info">
                            <h3 className="mb-4">Forgot Your Password?</h3>
                            <p>
                              Please enter the email address that you signed up with and<br/>
                              QuickRegistry.in will send you a verification code.
                            </p>
                          </div>
                          <div className="card-body">
                            <div className="user-box adjust-user-box mt-4">
                              <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleEmailSubmit(e)}
                                className={`form-control ${isEmailValid ? '' : 'is-invalid'}`}
                                autoComplete="off"
                                required
                              />
                              <label>Email</label>
                            </div>
                            {!fromMobileApp && (
                              <div className="mt-4">
                                <ReCAPTCHA
                                  sitekey="6Lcdq5IpAAAAAARu8Bqq8mxuUf_XVDcjLW5LVLx5"
                                  onChange={handleRecaptchaChange}
                                  style={{ display: "inline-block" }} 
                                />
                              </div>
                            )}
                            <button type="button" className="btn btn-primary reset-btn mt-4" onClick={handleEmailSubmit} disabled={loading}>
                            {loading ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Sending OTP... </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          </div>
                          </>
                          )}
                          {step === 2 && (
                          <>
                          <div className="reset-info">
                            <h3 className="mb-4">Reset Your Password</h3>
                            <p>
                              A verification code has been sent to {resetEmailAddress} from customercare@quickregistry.in. 
                              Please check your email and enter the code below. Then click verify to reset your password.
                            </p>
                            <p>
                              If you do not receive the email within the next few minutes, please check your spam folder or try again.
                              </p>
                          </div>
                          <div className="card-body">
                            <div className="otp-field mb-4">
                              <input type="number" />
                              <input type="number" disabled />
                              <input type="number" disabled />
                              <input type="number" disabled />
                              <input type="number" disabled />
                              <input type="number" disabled onKeyPress={(e) => e.key === 'Enter' && handleVerification(e)}/>
                            </div>
                            <button type="button" className="btn btn-primary reset-btn mb-2" onClick={handleVerification} disabled={loading}>
                            {loading ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Verifying... </>
                            ) : (
                              "Verify"
                            )}
                          </button>
                          <p className="resend text-muted">{remainingTime > 0 ? `Request again in ${remainingTime}s` : ''}</p>
                            <p className="resend text-muted"> Didn't receive code?&nbsp;
                              <Link className={`resend ${remainingTime > 0 || requestLoading  ? 'custom-disabled' : ''}`} onClick={handleRequestAgain} disabled={requestLoading}>
                               {requestLoading ? 'Requesting...' : 'Request again'}
                              </Link>
                            </p>
                            </div>
                            </>
                          )}
                          {step === 3 && (
                            <>
                          <div className="reset-info">
                            <h3 className="mb-4">Reset Your Password</h3>
                            <p> Reset the password for {resetEmailAddress} by entering a new one below. </p>
                          </div>
                          <div className="card-body">
                          <div className="user-box adjust-user-box mt-5 mb-4">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              name="new-password"
                              onChange={handleNewPasswordChange}
                              onKeyPress={(e) => e.key === 'Enter' && handleSave(e)}
                              className={`form-control ${isNewPasswordValid ? '' : 'is-invalid'}`}
                              maxLength={20}
                              required
                            />
                            <label>New Password</label>
                            <Link
                              className="password-toggle-icon position-absolute end-0 bottom-50 me-3"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {showNewPassword ? (
                                <i className="las la-eye-slash fs-4"></i>
                              ) : (
                                <i className="las la-eye fs-4"></i>
                              )}
                            </Link>
                            <p className="forgot-password-hint">8-20 characters</p>
                          </div>
                          <div className="user-box adjust-user-box mb-3">
                            <input
                              type={showRetypePassword ? "text" : "password"}
                              name="retype-new-password"
                              onChange={handleRetypePasswordChange}
                              onKeyPress={(e) => e.key === 'Enter' && handleSave(e)}
                              className={`form-control ${isRetypePasswordValid ? '' : 'is-invalid'}`}
                              maxLength={20}
                              required
                            />
                            <label>Retype New Password</label>
                            <Link
                            className="password-toggle-icon password-icon position-absolute end-0 top-50 me-3"
                            onClick={toggleRetypePasswordVisibility}
                          >
                            {showRetypePassword ? (
                              <i className="las la-eye-slash fs-4"></i>
                            ) : (
                              <i className="las la-eye fs-4"></i>
                            )}
                          </Link>
                          </div>
                            <button type="button" className="btn btn-primary reset-btn" onClick={handleSave} disabled={loading}>
                            {loading ? (
                              <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Saving... </>
                            ) : (
                              "Save"
                            )}
                          </button>
                            </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;