/* global $ */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import ChangePasswordModal from "../modal/ChangePasswordModal";
import { getRegistryCredentials, clearRegistryCredentials } from '../../../utils/registryUtils';
import api from "../../../services/api";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

const ProfileTab = ({ settingDetails, fetchSettingDetails }) => {
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validateMyDetails, setValidatemyDetails] = useState(false);
  const [validateShippingInfo, setValidateshippingInfo] = useState(false);
  const [myDetailsEditMode, setMyDetailsEditMode] = useState(false);
  const [shippingInfoEditMode, setShippingInfoEditMode] = useState(false);
  const [selectedMyRole, setSelectedMyRole] = useState("");
  const [selectedPartnerRole, setSelectedPartnerRole] = useState("");
  const { accessToken, registryTypeId, registryId, userId } = getRegistryCredentials();
  const [myDetails, setMyDetails] = useState({
    myFirstName: "",
    myLastName: "",
    partnerFirstName: "",
    partnerLastName: "",
    email: "",
    phoneNumber: "",
  });

  const [shippingInfo, setShippingInfo] = useState({
    shipToFirstName: "",
    shipToLastName: "",
    streetAddress1: "",
    streetAddress2: "",
    zipCode: "",
    city: "",
    stateProvince: "",
    country: "",
    instructions: "",
  });

  useEffect(() => {
    if (settingDetails) {
      setMyDetails({
        myFirstName: settingDetails.user_details.first_name || "",
        myLastName: settingDetails.user_details.last_name || "",
        partnerFirstName: settingDetails.user_details.partner_first_name,
        partnerLastName: settingDetails.user_details.partner_last_name,
        email: settingDetails.user_details.email || "",
        phoneNumber: settingDetails.user_details.mobile_no || "",
      });
  
      setShippingInfo({
        shipToFirstName: settingDetails.shipping_info.first_name,
        shipToLastName: settingDetails.shipping_info.last_name,
        streetAddress1: settingDetails.shipping_info.address_1,
        streetAddress2: settingDetails.shipping_info.address_2 || "",
        zipCode: settingDetails.shipping_info.zip_code,
        city: settingDetails.shipping_info.city,
        stateProvince: settingDetails.shipping_info.state,
        country: settingDetails.shipping_info.country,
        instructions: settingDetails.shipping_info.instructions || "",
      });
  
      const userAnswers = settingDetails.user_answers;
      
      if (userAnswers.length >= 3) {
        const myRoleAnswer = userAnswers[0];
        setSelectedMyRole(myRoleAnswer.question_answer);
  
        const myPartnerRoleAnswer = userAnswers[1];
        setSelectedPartnerRole(myPartnerRoleAnswer.remarks);
  
        const partnerNameAnswer = userAnswers[1];
        if (partnerNameAnswer && partnerNameAnswer.question_answer) {
          const [partnerFirstName, partnerLastName] =
            partnerNameAnswer.question_answer.split(" ");
          setMyDetails((prevDetails) => ({
            ...prevDetails,
            partnerFirstName: partnerFirstName || "",
            partnerLastName: partnerLastName || "",
          }));
        }
      }
    }
  }, [settingDetails]);

  const handleToggleMyDetails = () => {
    setMyDetailsEditMode(!myDetailsEditMode);
  };

  const handleToggleShippingInfo = () => {
    setShippingInfoEditMode(!shippingInfoEditMode);
  };

  const handleDeleleAccount = () => {
    $('#DeleteModal').modal('show');
  };

  const handleChangeDetails = (field, value) => {
    let updatedValue = value;

    if (field === "phoneNumber") {
      const validPhoneNumber = value.replace(/\D/g, "");
      updatedValue = validPhoneNumber.slice(0, 10);
    }
    setMyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: updatedValue,
    }));
  };

  const handleChangeShipping = (field, value) => {
    setShippingInfo((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSaveMyDetails = async () => {
    setValidatemyDetails(true);
  
    if (!myDetails.myFirstName || !myDetails.myLastName) {
      showToast("Please fill in all required fields");
      return;
    }
  
    if (myDetails.partnerFirstName && !myDetails.partnerLastName) {
      showToast("Please enter partner's last name");
      return;
    }
  
    if (!myDetails.partnerFirstName && myDetails.partnerLastName) {
      showToast("Please enter partner's first name");
      return;
    }
  
    if (myDetails.partnerFirstName && myDetails.partnerLastName && !selectedPartnerRole) {
      showToast("Please select partner's role");
      return;
    }
  
    if (myDetails.phoneNumber && !/^\d{10}$/.test(myDetails.phoneNumber)) {
      showToast("Phone number must be 10 digits");
      return;
    }
  
    setLoading(true);
    try {
      let questionIds;
      if (registryTypeId === "1") {
        questionIds = { myRole: "1", partnerName: "2", partnerRole: "3" };
      } else if (registryTypeId === "2") {
        questionIds = { myRole: "4", partnerName: "5", partnerRole: "6" };
      } else {
        showToast("Unsupported registry type");
        setLoading(false);
        return;
      }
  
      const partnerName = myDetails.partnerFirstName && myDetails.partnerLastName
        ? `${myDetails.partnerFirstName} ${myDetails.partnerLastName}`
        : null;
  
      await api.updateProfile(accessToken, userId, {
        registry_id: registryId,
        user_details: {
          first_name: myDetails.myFirstName,
          last_name: myDetails.myLastName,
          mobile_no: myDetails.phoneNumber,
        },
        responses: [
          {
            registry_question_id: questionIds.myRole,
            question_answer: selectedMyRole,
            remarks: null,
          },
          {
            registry_question_id: questionIds.partnerName,
            question_answer: partnerName,
            remarks: selectedPartnerRole,
          },
        ],
      });
  
      setMyDetailsEditMode(false);
      showToast("Profile details updated successfully", "success");
      fetchSettingDetails();
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error.message);
      showToast("Failed to update profile details", "error");
    }
  };  

  const handleSaveShippingInfo = async () => {
    setValidateshippingInfo(true);

    if (
      !shippingInfo.shipToFirstName ||
      !shippingInfo.shipToLastName ||
      !shippingInfo.streetAddress1 ||
      !shippingInfo.zipCode
    ) {
      showToast("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const shippingData = {
        first_name: shippingInfo.shipToFirstName,
        last_name: shippingInfo.shipToLastName,
        address_1: shippingInfo.streetAddress1,
        address_2: shippingInfo.streetAddress2 || "",
        city: shippingInfo.city,
        state: shippingInfo.stateProvince,
        country: shippingInfo.country,
        zip_code: shippingInfo.zipCode,
        instructions: shippingInfo.instructions || "",
      };

      await api.updateShipping(accessToken, registryId, shippingData);

      showToast("Shipping info updated successfully", "success");
      setShippingInfoEditMode(false);
      fetchSettingDetails();
      setLoading(false);
    } catch (error) {
      console.error("Error updating shipping information:", error.message);
      showToast("Failed to update shipping information", "error");
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await api.deleteRegistryPermanently(accessToken, registryId);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      window.location.pathname = "/";
    } catch (error) {
      console.error("Error deleting account:", error.message);
      showToast("Failed to delete account", "error");
      $('#DeleteModal').modal('hide');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancel = () => {
    $('#DeleteModal').modal('hide');
  };

  return (
    <div className="accordion-item">
        
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Profile</button>
    </h2>
    
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion1">
      <div className="accordion-body">
        <div className="row">            
          <div className="col-md-6">
            <div className={myDetailsEditMode ? "edit-mode" : "view-mode"}>
            {myDetailsEditMode ? (
              <div className="edit-mode">
                <div className="view-heading">
                  <h3>{registryTypeId === "2" ? "Our Details" : "My Details"}</h3>
                  <div className="user-detials-btn"> 
                    <button type="button" className="settings-btn" onClick={handleToggleMyDetails}>Cancel</button> 
                    <button type="button" className="btn settings-btn active-btn" onClick={handleSaveMyDetails} disabled={loading}>Save</button> 
                  </div>
                </div>
                              
                <div className="user-details mt-4">
                  <div className="full-name">
                    <div className="user-settings edit-mode-editable">
                      <div className="user-box">
                        <input type="text" className={`form-control ${validateMyDetails && !myDetails.myFirstName ? "is-invalid" : ""}`} required value={myDetails.myFirstName} onChange={(e) => handleChangeDetails('myFirstName', e.target.value)}/>
                        <label>FIRST NAME</label>
                      </div>
                      <div className="user-box">
                        <input type="text" className={`form-control ${validateMyDetails && !myDetails.myLastName ? "is-invalid" : ""}`} required value={myDetails.myLastName} onChange={(e) => handleChangeDetails('myLastName', e.target.value)}/>
                        <label>LAST NAME</label>
                      </div>
                    </div>
                    <div className="partner-name-edit">
                      {registryTypeId === "1" && (
                        <>
                        <Link className={selectedMyRole === 'Mom' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Mom')}>Mom</Link>&nbsp;
                        <Link className={selectedMyRole === 'Dad' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Dad')}>Dad</Link>&nbsp;
                        <Link className={selectedMyRole === 'Partner' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Partner')}>Partner</Link>
                        </>
                      )}
                      {registryTypeId === "2" && (
                        <>
                          <Link className={selectedMyRole === 'Bride' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Bride')}>Bride</Link>&nbsp;
                          <Link className={selectedMyRole === 'Groom' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Groom')}>Groom</Link>&nbsp;
                          <Link className={selectedMyRole === 'Partner' ? "active-partner" : ""} onClick={() => setSelectedMyRole('Partner')}>Partner</Link>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="full-name">
                    <div className="user-settings edit-mode-editable">
                      <div className="user-box">
                        <input type="text" className={`form-control ${validateMyDetails && !myDetails.partnerFirstName && myDetails.partnerLastName ? "is-invalid" : ""}`} required value={myDetails.partnerFirstName} onChange={(e) => handleChangeDetails('partnerFirstName', e.target.value)}/>
                        <label>PARTNER'S FIRST NAME</label>
                      </div>
                      <div className="user-box">
                        <input type="text" className={`form-control ${validateMyDetails && !myDetails.partnerLastName && myDetails.partnerFirstName ? "is-invalid" : ""}`} required value={myDetails.partnerLastName} onChange={(e) => handleChangeDetails('partnerLastName', e.target.value)}/>
                        <label>PARTNER'S LAST NAME</label>
                      </div>
                    </div>
                    <div className="partner-name-edit">
                      {registryTypeId === "1" && (
                        <>
                          <Link className={selectedPartnerRole === 'Mom' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Mom')}>Mom</Link>&nbsp;
                          <Link className={selectedPartnerRole === 'Dad' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Dad')}>Dad</Link>&nbsp;
                          <Link className={selectedPartnerRole === 'Partner' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Partner')}>Partner</Link>
                        </>
                      )}
                      {registryTypeId === "2" && (
                        <>
                          <Link className={selectedPartnerRole === 'Bride' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Bride')}>Bride</Link>&nbsp;
                          <Link className={selectedPartnerRole === 'Groom' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Groom')}>Groom</Link>&nbsp;
                          <Link className={selectedPartnerRole === 'Partner' ? "active-partner" : ""} onClick={() => setSelectedPartnerRole('Partner')}>Partner</Link>
                        </>
                      )}
                    </div>
                  </div>
                  
                  <div className="full-name">
                    <div className="user-settings edit-mode-editable-full">
                      <div className="user-box">
                        <input type="text" required value={myDetails.email} disabled/>
                        <label>EMAIL</label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="user-settings">
                    <div className="settings-label">password</div>
                    <div className="settings-input-text">********
                      <div className="change-password">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#change-password">Change Password</button>
                      </div>
                    </div>
                  </div>
                  
                  <div className="full-name">
                    <div className="user-settings edit-mode-editable-full">
                      <div className="user-box">
                        <input type="tel" className={`form-control ${validateMyDetails && myDetails.phoneNumber && !/^\d{10}$/.test(myDetails.phoneNumber) ? "is-invalid" : ""}`} required value={myDetails.phoneNumber} onChange={(e) => handleChangeDetails('phoneNumber', e.target.value)}/>
                        <label>PHONE NUMBER</label>
                      </div>
                      {/* <div className="user-box">
                        <input type="text" className={`form-control ${validateMyDetails && !myDetails.location ? "is-invalid" : ""}`} required value={myDetails.location} onChange={(e) => handleChangeDetails('location', e.target.value)}/>
                        <label>Location*</label>
                      </div> */}
                    </div>
                    {/* <div className="note">
                      <p>* Enter the location (e.g. city & state) that you would like to appear in search results when guests are looking for your registry</p>
                    </div> */}
                  </div>
                </div>
              </div>
              ) : (
              <div className={`view-mode ${shippingInfoEditMode ? 'custom-disabled' : ''}`}>         
                <div className="view-heading">
                  <h3>{registryTypeId === "2" ? "Our Details" : "My Details"}</h3>
                  <div className="user-detials-btn"> 
                  <button type="button" className="settings-btn" onClick={handleToggleMyDetails}>Edit</button> </div>
                </div>
                
                <div className="user-details">
                  <div className="user-settings">
                    <div className="settings-label">{selectedMyRole}</div>
                    <div className="settings-input-text">{myDetails.myFirstName} {myDetails.myLastName}</div>
                  </div>
                  
                  {selectedPartnerRole && (myDetails.partnerFirstName || myDetails.partnerLastName) && (
                    <div className="user-settings">
                      <div className="settings-label">{selectedPartnerRole}</div>
                      <div className="settings-input-text">{myDetails.partnerFirstName} {myDetails.partnerLastName}</div>
                    </div>
                  )}
                  
                  <div className="user-settings">
                    <div className="settings-label">email</div>
                    <div className="settings-input-text">{myDetails.email}</div>
                  </div>
                  
                  <div className="user-settings">
                    <div className="settings-label">password</div>
                    <div className="settings-input-text">*******</div>
                  </div>
                  
                  <div className="user-settings">
                    <div className="settings-label">
                      My Phone Number
                      <span className="settings-note">(same across all registries)</span>
                    </div>
                    <div className="settings-input-text">+91 {myDetails.phoneNumber}</div>
                  </div>
                  
                  {/* <div className="user-settings">
                    <div className="settings-label">Location</div>
                    <div className="settings-input-text">{myDetails.location}</div>
                  </div> */}
                  
                  <div className="user-settings">
                    <div className="user-deactivate"><Link onClick={handleDeleleAccount}>Delete QuickRegistry Account</Link></div>
                  </div>
                </div>
               </div>
              )}
              </div>
            </div>
          
          <div className="col-md-6">
            <div className={shippingInfoEditMode ? "edit-mode" : "view-mode"}>
              {shippingInfoEditMode ? (
                <div className="edit-mode">
                  <div className="view-heading">
                    <h3>Shipping info</h3>
                    <div className="user-detials-btn"> 
                      <button type="button" className="settings-btn" onClick={handleToggleShippingInfo}>Cancel</button> 
                      <button type="button" className="btn settings-btn active-btn" onClick={handleSaveShippingInfo} disabled={loading}>Save</button> 
                    </div>
                  </div>

                  <div className="user-details mt-4">
                    <div className="full-name">
                      <div className="user-settings edit-mode-editable more-margin">
                        <div className="user-box">
                          <input type="text" className={`form-control ${validateShippingInfo && !shippingInfo.shipToFirstName ? "is-invalid" : ""}`} required value={shippingInfo.shipToFirstName} onChange={(e) => handleChangeShipping('shipToFirstName', e.target.value)} />
                          <label>FIRST NAME</label>
                        </div>
                        <div className="user-box">
                          <input type="text" className={`form-control ${validateShippingInfo && !shippingInfo.shipToLastName ? "is-invalid" : ""}`} required value={shippingInfo.shipToLastName} onChange={(e) => handleChangeShipping('shipToLastName', e.target.value)} />
                          <label>LAST NAME</label>
                        </div>
                      </div>
                    </div>
                    
                    <div className="full-name">
                      <div className="user-settings edit-mode-editable-full">
                        <div className="user-box">
                          <input type="text" className={`form-control ${validateShippingInfo && !shippingInfo.streetAddress1 ? "is-invalid" : ""}`} required value={shippingInfo.streetAddress1} onChange={(e) => handleChangeShipping('streetAddress1', e.target.value)} />
                          <label>STREET ADDRESS</label>
                        </div>
                      </div>
                      <div className="user-settings edit-mode-editable-full">
                        <div className="user-box">
                          <input type="text" value={shippingInfo.streetAddress2} onChange={(e) => handleChangeShipping('streetAddress2', e.target.value)} />
                          <label>STREET ADDRESS 2</label>
                        </div>
                      </div>
                    </div>
                    
                    <div className="full-name">
                      <div className="user-settings edit-mode-editable">
                        <div className="user-box">
                          <input type="text" className={`form-control ${validateShippingInfo && !shippingInfo.zipCode ? "is-invalid" : ""}`} value={shippingInfo.zipCode} onChange={(e) => handleChangeShipping('zipCode', e.target.value)} />
                          <label>Zip Code</label>
                        </div>
                        <div className="user-box">
                          <input type="text" name="" required value={shippingInfo.city} onChange={(e) => handleChangeShipping('city', e.target.value)} />
                          <label>City</label>
                        </div>
                      </div>
                    </div>

                    <div className="full-name">
                      <div className="user-settings edit-mode-editable-full state">
                        <div className="user-box">
                          <input type="text" name="" required value={shippingInfo.stateProvince} onChange={(e) => handleChangeShipping('stateProvince', e.target.value)} />
                          <label>STATE/PROVINCE:</label>
                        </div>
                      </div>

                      <div className="user-settings edit-mode-editable-full">
                        <div className="gifts-bought country-full-width">
                              <div className="service-provider">
                                <label>COUNTRY:</label>
                                <div className="select-wrapper">
                                  <select name="Country" id="country" className="form-control" value={shippingInfo.country} onChange={(e) => handleChangeShipping('country', e.target.value)} >
                                  <option value="India">India</option>
                                  <option value="USA">USA</option>
                                  <option value="Canada">Canada</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  </select>
                                </div>
                              </div>
                          </div>
                      </div>

                      <div className="user-settings edit-mode-editable-full">
                        <div className="user-box">
                          <input type="text" name="" required value={shippingInfo.instructions} onChange={(e) => handleChangeShipping('instructions', e.target.value)} />
                          <label>INSTRUCTIONS:</label>
                        </div>
                      </div>
                    </div>                
                  </div>
                </div>
                ) : (
                <div className={`view-mode ${myDetailsEditMode ? 'custom-disabled' : ''}`}>
                  <div className="view-heading">
                    <h3>Shipping info</h3>
                    <div className="user-detials-btn"> 
                    <button type="button" className="settings-btn" onClick={handleToggleShippingInfo}>Edit</button> </div>
                  </div> 
                  <div className="user-details">
                    <div className="user-settings">
                    <div className="settings-label">SHIP-TO NAME</div>
                    <div className="settings-input-text">{shippingInfo.shipToFirstName} {shippingInfo.shipToLastName}</div>
                  </div>
                  <div className="user-settings">
                    <div className="settings-label">SHIPPING ADDRESS</div>
                      <div className="settings-input-text">
                        {shippingInfo.streetAddress1 && (
                          <>
                            {shippingInfo.streetAddress1} {shippingInfo.streetAddress2 && `${shippingInfo.streetAddress2} `}
                          </>
                        )}
                        <br />
                        {shippingInfo.city && `${shippingInfo.city}, `}
                        {shippingInfo.stateProvince && `${shippingInfo.stateProvince}, `}
                        {shippingInfo.zipCode && `${shippingInfo.zipCode}`}
                        <br />
                        {shippingInfo.country}
                      </div>
                    </div>
                  <div className="user-settings">
                    <div className="settings-label">INSTRUCTIONS</div>
                    <div className="settings-input-text">{shippingInfo.instructions || 'None'}</div>
                  </div>
                </div>
              </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-modal-popup">
          <div className="modal-content">
            <div className="modal-body row">
              <div className="col-lg-12">
                <div className="confirmation-visitor-modal">
                  <h4 className="mt-4 text-center">Delete Permanently</h4>
                  <p className="delete-registry-info">We're Sorry to See You Go.</p>
                  <p>Once you delete this registry all the registry information will be deleted permanently.</p>
                </div>
                <div className="user-detials-btn text-end mt-4 mb-3">
                  <button type="button" className="common-modal-btn common-btn mb-3" onClick={handleCancel}>
                    CANCEL
                  </button>
                  <button type="button" className="btn common-modal-btn common-btn custom-del-btn active-btn" onClick={handleDelete} disabled={isDeleting}>
                    {isDeleting ? (
                      <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> DELETING REGISTRY... </>
                    ) : (
                      `DELETE ${settingDetails?.registry_name?.toUpperCase() || ''}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <ChangePasswordModal/>
  </div>
);
};
  
export default ProfileTab;