import React from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";

const AboutUsPage = () => {

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="about-us text-justify">
                      <p className="aboutus-heading text-center">
                          About QuickRegistry.in
                      </p>
                      <div className="about-section mt-5">
                        <h3 className="aboutus-subheading">
                          Welcome  to Quick Registry: Simplifying Gift-Giving in India 🎁
                        </h3>
                        <p>
                          Quick Registry is a concept that is revolutionising gift-giving in India! Traditionally, selecting the perfect 
                          gift has always been a thoughtful process, embedded deeply in our culture, especially during significant events 
                          like weddings, baby showers, Babies, Birthdays, housewarmings etc. However, as we embrace modernity, the way we 
                          give and receive gifts is evolving. This delves into the mechanics of a gift registry and it will become an 
                          integral part of contemporary Indian celebrations.
                        </p>
                      </div>

                      <div className="user-detials-btn btn-section text-center w-100">
                        <button type="button" className="common-btn active-btn" onClick={() => scrollToSection('benefits-section')}>Quick Registry Benefits</button>
                        <button type="button" className="common-btn active-btn second-float-btn" onClick={() => scrollToSection('why-qr-section')}>Why Quick Registy ?</button>
                        <button type="button" className="common-btn active-btn" onClick={() => scrollToSection('working-section')}>Quick Registry Working</button>
                      </div>

                      <div id="benefits-section" className="divided-section mt-5 d-flex">
                        <div className="point-spacing w-100 custom-width-60">
                          <div className="lifafa-bar aboutus-bar text-center mb-4">
                            <h4 className="visit-heading">The  Benefits of a Quick Registry</h4>
                          </div>
                          {/* <h3 className="aboutus-subheading mb-3">
                            The  Benefits of a Quick Registry
                          </h3> */}
                          <ul>
                            <li>
                            <p>
                                <b>Convenience:</b> Quick Registry simplifies the process for both the host and guests, making it easy to
                                choose and give gifts. 🎉
                            </p>
                            </li>
                            <li>
                            <p>
                                <b>Desired Gifts:</b> It ensures that you receive gifts that are truly useful and wanted, reducing the
                                guesswork for your guests. 🎯
                            </p>
                            </li>
                            <li>
                            <p>
                                <b>No Duplicates:</b> Avoid the awkwardness of duplicate gifts with our thoughtful registry system. 🚫
                            </p>
                            </li>
                            <li>
                            <p>
                                <b>Financially Practical:</b> For guests, a registry can be a more cost-effective and practical way to gift,
                                allowing them to pick items within their budget. 💸
                            </p>
                            </li>
                          </ul>
                        </div>
                        <div className="aboutus-image w-100 custom-width-40 d-none d-lg-flex justify-content-center align-items-center ms-5">
                          <img src="/assets/images/benefits.jpeg" alt="Quick Registry Benefits" className="img-fluid" />
                        </div>
                      </div>

                      <div className="divided-section mt-5 d-flex">
                        <div className="w-100 custom-width-40 d-none d-lg-flex justify-content-center align-items-center">
                          <img src="/assets/images/Inspiration.png" alt="Inspiration" className="img-fluid" />
                        </div>
                        <div className="w-100 custom-width-60">
                          <div className="lifafa-bar aboutus-bar text-center mb-4">
                            <h4 className="visit-heading">🌟The  Spark of Inspiration 🌟</h4>
                          </div>
                          {/* <h3 className="aboutus-subheading">
                            🌟The  Spark of Inspiration 🌟
                          </h3> */}
                          <p>
                            It all began with a birthday outing in September 2023 to the Pink City of India, where my husband and I visited
                            friends who had recently welcomed new babies. As a gesture we wanted to take gifts for which we faced a common
                            dilemma— we wandered through limited branded store options and the fear of giving something unwanted or duplicate.
                            The questions, the doubts, and the endless what-ifs danced in our heads. “Will they already have this?”, “Is this
                            colour too bland?”, “Would they even like this? What if they didn't like our choices, or had received the same thing
                            from someone else?” we realised the challenge of finding something unique and desired by the recipients.
                            <br/><br/>
                            At that time, I was also pregnant with my first child so I could relate it to myself. What if I will also get 
                            so many undesired gifts which will be a complete waste of money and energy for everyone. That night, under a 
                            canopy of stars, Quick Registry was born - the idea sparked to create a platform where everyone can share 
                            exactly what they want or need 🎂✨
                          </p>
                        </div>
                      </div>

                      <div id="why-qr-section" className="divided-section mt-5 d-flex">
                        <div className="point-spacing w-100 custom-width-55">
                          <div className="lifafa-bar aboutus-bar text-center mb-4">
                            <h4 className="visit-heading">Why Quick Registry?</h4>
                          </div>
                          {/* <h3 className="aboutus-subheading mb-3">
                            Why Quick Registry?
                          </h3> */}
                          <ul>
                            <li>
                            <p>
                                <b>Personal Connection:</b> Our platform allows you  to  give  gifts  that  truly  resonate with the
                                recipients, bridging distances and strengthening bonds. ❤
                            </p>
                            </li>
                            <li>
                            <p>
                                <b>Sustainability in Gifting:</b> By  ensuring  that  every  gift  is  wanted,  we  help  reduce waste
                                and promote  sustainability, making each celebration eco-friendlier. 🌍
                            </p>
                            </li>
                            <li>
                            <p>
                                <b>Simplified Gifting:</b> No more guesswork or last-minute shopping stress. Quick Registry makes it easy
                                to find and give the perfect gift, tailored to the receiver ’s preferences. 🎈
                            </p>
                            </li>
                          </ul>
                        </div>
                        <div className="w-100 w-lg-50 custom-width-45 d-none d-lg-flex justify-content-center align-items-center ms-5">
                          <img src="/assets/images/gift.png" alt="Why Quick Registry" className="img-fluid rounded-pill" />
                        </div>
                      </div>
                      <div id="working-section" className="point-spacing mt-5">
                        <h3 className="aboutus-subheading mb-3">
                          How QR Works?
                        </h3>
                        <ol>
                          <li>
                          <p>
                              <b>Creating the Registry:</b> Setting up your registry involves selecting items you wish to receive from 
                              any online store. The items might range from weddings to newborn essentials, home appliances, honeymoon or
                              could be any celebration, reflecting your personal style and needs.
                          </p>
                          </li>
                          <li>
                          <p>
                              <b>Sharing the Registry:</b> Share your registry with your guests in a way that respects cultural sensitivities.
                              A tactful approach ensures that the registry is seen as a convenience rather than a demand.
                          </p>
                          </li>
                          <li>
                          <p>
                              <b>Receiving Gifts:</b> Gifts can either be shipped directly to you or to a gift giver. This process simplifies
                              the management of gifts, making it a seamless experience.
                          </p>
                          </li>
                        </ol>
                      </div>
                      <div className="mt-5">
                        <h3 className="aboutus-subheading">
                          Our  Vision & Mission 🎯
                        </h3>
                        <p>
                          We aim to transform how gifts are given and received in India. Our mission is to ensure that every gift you
                          choose is perfect, wanted, and enhances the joy not just to the receiver but to the giver as well. We believe
                          that gifting should be thoughtful, personalised, and absolutely stress-free.
                          <br/><br/>
                          While gift registries are practical, it represent the evolving nature of gift-giving in India, adding a layer
                          of convenience and practicality to our rich traditions. They are not just about receiving what you need, but
                          also about respecting the time and effort of your guests.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h3 className="aboutus-subheading">
                          Your  Voice  Matters to Us! 📣
                        </h3>
                        <p>
                          Got a Quick Registry story? A suggestion on how we can be even better? Or just want to say hi? Drop us a line!
                          Your feedback and stories inspire us to keep innovating and making gifting magic. 💬
                        </p>
                      </div>
                      <div className="mt-5">
                        <h3 className="aboutus-subheading">
                          Welcome  to Our  Family 👨‍👩‍👧‍👦
                        </h3>
                        <p>
                          Join us in celebrating life's moments with gifts that tell a story. Welcome to Quick Registry, where your gifts
                          do more than please—they connect and resonate deeply. So what are you waiting for? Let's make gifting occasions
                          memorable together! 🥳
                        </p>
                      </div>
                      <div className="my-5">
                        <h3 className="aboutus-subheading">
                          Let's Make  Gifting Memorable  Together! 🥳
                        </h3>
                        <p>
                          Hey there! You’ve just discovered the cozy corner of the internet where gift-giving transforms from <b>“Oh no,
                          what do I get?”</b> to <b>“Oh wow, this is perfect!”</b>
                          <br/><br/>
                          Quick Registry — a place where giving the perfect gift is easy and ensures it’s exactly what the receiver wants.
                          <br/><br/>
                          <b>Come join us and experience the magic of gifting redefined, sprinkled with a lot of love and crafted just for you.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUsPage;
