/* global $ */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Navbar from '../../components/common/Navbar';
import Pagination from '../../pagination/Pagination';
import { showToast } from '../../utils/toastUtils';
import { shopImages, coverImages } from '../../utils/imagesUtils';
import ViewOnlineModal from '../../components/dashboard/modal/ViewOnlineModal';
import AddShopGiftModal from '../../components/dashboard/modal/AddShopGiftModal';
import api from '../../services/api';

const ProductPage = () => {
  const { category } = useParams();
  const allShopImages = shopImages();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const image = allShopImages.find(img => img.link.includes(category));
  const coverImage = coverImages.find(cover => cover.alt.toLowerCase().replace(/ /g, '-').includes(category));

  useEffect(() => {
    fetchProducts();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [currentPage, category]);

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const brand = category.replace(/-/g, '');
      const response = await api.getShopProducts(currentPage, brand);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      showToast("Unable to fetch products", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApplyFilter = () => {
    $("#filter-modal").modal("hide");
  };

  const handleAddToWishlist = (productId) => {
    setSelectedProductId(productId);
    $("#ShopGift").modal("show");
  };

  const handleViewOnline = (productId) => {
    setProductId(productId);
    $("#view-online-modal").modal("show");
  };

  const filterCategory = (
    <>
      <div className="accordion" id="filterAccordion">
        <div className="accordion-item cat-section">
          <h2 className="accordion-header" id="headingCategory">
            <button className="accordion-button cat-accordion-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCategory" aria-expanded="true" aria-controls="collapseCategory">
              Category
            </button>
          </h2>
          <div id="collapseCategory" className="accordion-collapse collapse show cat-section" aria-labelledby="headingCategory">
            <div className="accordion-body">
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="all-categories" />
                  <span className="checkbox-custom"></span>
                  All Categories
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="bath" />
                  <span className="checkbox-custom"></span>
                  Bath
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="changing" />
                  <span className="checkbox-custom"></span>
                  Changing
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="clothing" />
                  <span className="checkbox-custom"></span>
                  Clothing
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="feeding" />
                  <span className="checkbox-custom"></span>
                  Feeding
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="health" />
                  <span className="checkbox-custom"></span>
                  Health
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="new-parents" />
                  <span className="checkbox-custom"></span>
                  New Parents
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="nursery" />
                  <span className="checkbox-custom"></span>
                  Nursery
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="playing" />
                  <span className="checkbox-custom"></span>
                  Playing
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="pregnancy" />
                  <span className="checkbox-custom"></span>
                  Pregnancy
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="travel" />
                  <span className="checkbox-custom"></span>
                  Travel
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item cat-section">
          <h2 className="accordion-header" id="headingBrand">
            <button className="accordion-button cat-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBrand" aria-expanded="false" aria-controls="collapseBrand">
              Brand
            </button>
          </h2>
          <div id="collapseBrand" className="accordion-collapse collapse cat-section" aria-labelledby="headingBrand">
            <div className="accordion-body">
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="firstcry" />
                  <span className="checkbox-custom"></span>
                  Firstcry
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="littleMuffet" />
                  <span className="checkbox-custom"></span>
                  Little Muffet
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="mumkins" />
                  <span className="checkbox-custom"></span>
                  Mumkins
                </label>
              </div>
              {/* <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="hopscotch" />
                  <span className="checkbox-custom"></span>
                  Hopscotch
                </label>
              </div> */}
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="r-for-rabbit" />
                  <span className="checkbox-custom"></span>
                  R for Rabbit
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="mothercare" />
                  <span className="checkbox-custom"></span>
                  Mothercare
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="carters" />
                  <span className="checkbox-custom"></span>
                  Carter's
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="hm" />
                  <span className="checkbox-custom"></span>
                  H&M
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="zara" />
                  <span className="checkbox-custom"></span>
                  Zara
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="hamleys" />
                  <span className="checkbox-custom"></span>
                  Hamleys
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="amazon" />
                  <span className="checkbox-custom"></span>
                  Amazon
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="myntra" />
                  <span className="checkbox-custom"></span>
                  Myntra
                </label>
              </div>
              <div className="filter-option">
                <label className="checkbox-label">
                  <input type="checkbox" name="ajio" />
                  <span className="checkbox-custom"></span>
                  Ajio
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item cat-section">
        <h2 className="accordion-header" id="headingWishlist">
          <button className="accordion-button cat-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWishlist" aria-expanded="false" aria-controls="collapseWishlist">
            Example Wishlists
          </button>
        </h2>
        <div id="collapseWishlist" className="accordion-collapse collapse cat-section" aria-labelledby="headingWishlist">
          <div className="accordion-body">
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="books" />
                <span className="checkbox-custom"></span>
                Books
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="christening" />
                <span className="checkbox-custom"></span>
                Christening
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gadget-lovers" />
                <span className="checkbox-custom"></span>
                Gadget Lovers
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-1-year-old" />
                <span className="checkbox-custom"></span>
                Gifts for a 1 Year Old
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-2-year-old" />
                <span className="checkbox-custom"></span>
                Gifts for a 2 Year Old
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-3-year-old" />
                <span className="checkbox-custom"></span>
                Gifts for a 3 Year Old
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-4-year-old" />
                <span className="checkbox-custom"></span>
                Gifts for a 4 Year Old
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-5-year-old" />
                <span className="checkbox-custom"></span>
                Gifts for a 5 Year Old
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="gifts-for-a-baby" />
                <span className="checkbox-custom"></span>
                Gifts for a Baby
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="hospital-bag" />
                <span className="checkbox-custom"></span>
                Hospital Bag
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="personalised" />
                <span className="checkbox-custom"></span>
                Personalised
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="small-businesses" />
                <span className="checkbox-custom"></span>
                Small Businesses
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="something-for-mum" />
                <span className="checkbox-custom"></span>
                Something for Mum
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="something-special" />
                <span className="checkbox-custom"></span>
                Something Special
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="subscriptions" />
                <span className="checkbox-custom"></span>
                Subscriptions
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="timeless-baby-items" />
                <span className="checkbox-custom"></span>
                Timeless Baby Items
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item cat-section">
        <h2 className="accordion-header" id="headingAge">
          <button className="accordion-button cat-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAge" aria-expanded="false" aria-controls="collapseAge">
            Age
          </button>
        </h2>
        <div id="collapseAge" className="accordion-collapse collapse cat-section" aria-labelledby="headingAge">
          <div className="accordion-body">
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-0-6-months" />
                <span className="checkbox-custom"></span>
                0 to 6 Months
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-6-months-1-year" />
                <span className="checkbox-custom"></span>
                6 Months to 1 Year
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-1-2-years" />
                <span className="checkbox-custom"></span>
                1 to 2 Years
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-2-3-years" />
                <span className="checkbox-custom"></span>
                2 to 3 Years
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-3-5-years" />
                <span className="checkbox-custom"></span>
                3 to 5 Years
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-5-7-years" />
                <span className="checkbox-custom"></span>
                5 to 7 Years
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-7-10-years" />
                <span className="checkbox-custom"></span>
                7 to 10 Years
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="age-10-years-and-over" />
                <span className="checkbox-custom"></span>
                10 Years and Over
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item cat-section">
        <h2 className="accordion-header" id="headingPriceRange">
          <button className="accordion-button cat-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePriceRange" aria-expanded="false" aria-controls="collapsePriceRange">
            Price Range
          </button>
        </h2>
        <div id="collapsePriceRange" className="accordion-collapse collapse cat-section" aria-labelledby="headingPriceRange">
          <div className="accordion-body">
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="price-under-1000" />
                <span className="checkbox-custom"></span>
                Gifts under ₹1000
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="price-under-2000" />
                <span className="checkbox-custom"></span>
                Gifts under ₹2000
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="price-under-5000" />
                <span className="checkbox-custom"></span>
                Gifts under ₹5000
              </label>
            </div>
            <div className="filter-option">
              <label className="checkbox-label">
                <input type="checkbox" name="price-5000-and-above" />
                <span className="checkbox-custom"></span>
                Gifts ₹5000 and Above
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="wrapper animsition">
      {loading && (
        <div className="spinner-overlay shop-spinner-bg">
          <div className="spinner-grow custom-shop-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section>
              <div className="shop-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="head-section">
                      <div className="category-cover">
                        {coverImage && (
                          <img src={coverImage.src} alt={coverImage.alt} />
                        )}
                      </div>
                      <div className="profile-wrapper">
                        {image && (
                          <img src={image.src} alt={image.alt} className="profile-image" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="custom-disabled filter-section d-none d-md-block">
                      <h3>Filter by:</h3>
                      {filterCategory}
                    </div>
                    <div className="d-md-none shop-filter">
                      <button className="form-btn" data-bs-toggle="modal" data-bs-target="#filter-modal">View Filter</button>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="product-grid">
                      <div className="row">
                        <div className="top-section d-flex flex-column flex-md-row justify-content-between align-items-center">
                          <div className="filter-part">
                            <label htmlFor="sortingselect">Sort By:</label>
                            <div className="custom-disabled select-wrapper sorting-shop">
                              <select
                                name="sorting"
                                id="sortingselect"
                                className="form-control auto-width"
                              >
                                <option value="">Latest</option>
                                <option value="price_high_to_low">Price: high to low</option>
                                <option value="price_low_to_high">Price: low to high</option>
                              </select>
                            </div>
                          </div>
                          <div className="search-part search-registry">
                            <input
                              type="text"
                              placeholder="Search"
                            />
                            <div className="search-icon-product">
                              <i className="la la-search reversed-icon"></i>
                            </div>
                          </div>
                        </div>
                        {products.map(product => (
                          <div key={product.id} className="col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                              <img src={product.image} alt={product.name} className="shop-product-image" style={{ height: "200px", objectFit: "contain" }}/>
                              <p className="product-name clamp-lines">{product.title}</p>
                              <p className="product-price">₹ {product.price}</p>
                              <button className="form-btn shop-btn" onClick={() => handleAddToWishlist(product.id)}>Add to Wishlist</button>
                              <button className="form-btn custom-edit-btn shop-btn" onClick={() => handleViewOnline(product.id)}>View Online</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='adjust-pagination'>
                      <Pagination currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <AddShopGiftModal
          products={products} 
          selectedProductId={selectedProductId}
        />
        <ViewOnlineModal 
          productId={productId} 
          currentPage={currentPage}
        />
        <Footer />
      </div>
      
      <div className="modal fade" id="filter-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>FILTER BY</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="filter-modal-section">
              <div className="modal-body">
                {filterCategory}
              </div>
              <div className="d-md-none shop-filter">
                <button className="form-btn" onClick={handleApplyFilter} >Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;