// import React from 'react';

// const Pagination = ({ totalPages, currentPage, onPageChange }) => {
//   const pageLimit = 5;

//   const handlePageChange = (page) => {
//     if (page >= 1 && page <= totalPages) {
//       onPageChange(page);
//     }
//   };

//   const renderPageNumbers = () => {
//     const startPage = Math.floor((currentPage - 1) / pageLimit) * pageLimit + 1;
//     const endPage = Math.min(startPage + pageLimit - 1, totalPages);
    
//     const pageNumbers = [];
//     for (let i = startPage; i <= endPage; i++) {
//       pageNumbers.push(
//         <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
//           <button className="page-link rounded-circle custom-page-link" onClick={() => handlePageChange(i)}>
//             {i}
//           </button>
//         </li>
//       );
//     }
//     return pageNumbers;
//   };

//   return (
//     <nav aria-label="Page navigation example">
//       <ul className="pagination justify-content-center">
//         <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//           <button className="page-link rounded-circle custom-page-link" onClick={() => handlePageChange(1)} tabIndex="-1" aria-disabled={currentPage === 1}>
//             <i className="la la-angle-double-left"></i>
//           </button>
//         </li>
//         <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//           <button className="page-link rounded-circle custom-page-link" onClick={() => handlePageChange(currentPage - 1)} tabIndex="-1" aria-disabled={currentPage === 1}>
//             <i className="la la-angle-left"></i>
//           </button>
//         </li>
//         {renderPageNumbers()}
//         <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//           <button className="page-link rounded-circle custom-page-link" onClick={() => handlePageChange(currentPage + 1)}>
//             <i className="la la-angle-right"></i>
//           </button>
//         </li>
//         <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//           <button className="page-link rounded-circle custom-page-link" onClick={() => handlePageChange(totalPages)}>
//             <i className="la la-angle-double-right"></i>
//           </button>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Pagination;

import React from 'react';

const Pagination = ({ currentPage, onPageChange }) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1) {
      onPageChange(newPage);
    }
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className="page-link custom-page-btn previous-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="la la-arrow-left"></i>
          </button>
        </li>
        <li className="page-item mx-2">
          <button
            className="page-link custom-page-btn next-btn"
            onClick={() => handlePageChange(currentPage + 1)}
          >
             Next Page <i className="la la-arrow-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;