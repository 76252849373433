import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const AddGiftsPage = () => {
  const handleButton = () => {
    window.open(
      "https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8",
      "_blank"
    );
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main main-dashboard">
          <article>
            <section>
              <div className="container">
                <div className="sub-heading text-center">
                  <h3>Easy way to add gifts</h3>
                </div>
                <div className="ways-to-add-gifts">
                  <h4 className="mb-3">
                    Add items from all your favorite stores
                  </h4>
                  <p className="easy-gift-sub-text">
                    Visit your favorite online stores, then click on the
                    "QuickRegistry.in" Extension when you find a must-have item.
                  </p>
                  <div className="qr-btn-container">
                    <div className="gift-btn mt-4">
                      <button type="button" onClick={handleButton}>
                        GET THE EXTENSION
                      </button>
                    </div>
                    <span className="or-text">or</span>
                    <div className="add-qr-list-container">
                      <div className="button">
                        <a
                          id="btnBookmark"
                          className="button-qr-outline qr-btn"
                          // eslint-disable-next-line no-script-url
                          href="javascript:(function(){var iframeUrl='https://quickregistry.in/add-gift-modal';var currentUrl=encodeURIComponent(window.location.href);var iframeSrc=iframeUrl+'?fromWeb=true&url='+currentUrl;if(!document.getElementById('AddToMr3Iframe')){var overlay=document.createElement('div');overlay.id='overlay';document.body.appendChild(overlay);overlay.innerHTML='<div id=\'backgroundOverlay\' style=\'position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #ddd; opacity: 0.9; z-index: 2147483646;\'></div><iframe id=\'AddToMr3Iframe\' src=\''+iframeSrc+'\' style=\'display: none; position: fixed; height: 600px; width: 900px; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2147483647; opacity: 1; clip: rect(26px, 791px, 570px, 91px);\'></iframe>';var iframe=document.getElementById('AddToMr3Iframe');iframe.addEventListener('load',function(){var pageHTMLContent=document.documentElement.outerHTML;iframe.contentWindow.postMessage({action:'sendHTML',pageHTMLContent:pageHTMLContent},'*');});setTimeout(function(){iframe.style.display='block';},6000);window.addEventListener('message',function(event){if(event.data.action==='closeIframe'){document.body.style.overflow='visible';var iframe=document.getElementById('AddToMr3Iframe');if(iframe){iframe.parentNode.removeChild(iframe);var overlay=document.getElementById('overlay');if(overlay){overlay.parentNode.removeChild(overlay);}}}});}})();"
                          onClick={(e) => e.preventDefault()}
                        >
                          🎁Add To QR List
                        </a>
                      </div>
                      <div className="arrow-icon">
                        <img src="assets/images/small_arrow.png" alt="Arrow icon" />
                      </div>
                      <div className="drag-text">
                        Drag Me
                      </div>
                    </div>
                  </div>
                  <p>
                    Learn more about how to add gifts using either the 
                    <Link to="#" className="change-color"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection('extension-works');
                      }}
                    > Extension </Link> 
                    or the 
                    <Link to="#" className="change-color"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection('bookmarks-works');
                      }}
                    > Bookmarks Button </Link>.
                  </p>
                </div>

                <div id="extension-works" className="ways-to-add-gifts">
                  <h4>How extension works</h4>
                  <div className="last-steps-container">
                    <div className="how-it-works-container">
                      <p className="easy-gift-sub-text">The “Quick Registry” Extension is what makes it possible to add items from any store in the world to your gift list on QuickRegistry.in</p>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">1</span> 
                      <span className="last-steps-details-container">
                      <span className="last-step-heading">
                          Add and <Link to="https://support.google.com/chrome/a/answer/11190170?hl=en" target="_blank" className="change-new-color">pin it</Link> to your browser
                      </span> <br />
                      <span>Just like your other extensions, it takes just a few clicks.</span>
                      </span>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">2</span>
                      <span className="last-steps-details-container">
                        <span className="last-step-heading">Find a gift you would like</span> <br />
                        <span>Visit any retail website and go the product page of the gift you’d like to add.</span>
                      </span>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">3</span>
                      <span className="last-steps-details-container">
                        <span className="last-step-heading">Add in Seconds</span> <br />
                        <span>Click on the button in your browser, confirm some details, add the gift.</span>
                      </span>
                    </div>
                  </div> 
                </div>

                <div id="bookmarks-works" className="ways-to-add-gifts">
                  <h4>How bookmarks button works</h4>
                  <div className="last-steps-container">
                    <div className="how-it-works-container">
                      <p className="easy-gift-sub-text">The "Quick Registry" bookmarks button lets you add any item from any online store to your gift list. (It works similar to the Pinterest button.)</p>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">1</span> 
                      <span className="last-steps-details-container">
                      <span className="last-step-heading">
                        Enable the Bookmarks Bar
                      </span> <br />
                      <span>In the menu for Chrome, located in the top right corner of your screen, go to <span className="less-bold">Bookmarks</span> and make sure there's a check mark next to <span className="less-bold">Show Bookmarks Bar</span>. Or, press <span className="less-bold">CTRL + SHIFT + B</span> on your keyboard.</span>
                      </span>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">2</span>
                      <span className="last-steps-details-container">
                        <span className="last-step-heading">Add the Quick Registry Button to Your Bookmarks</span> <br />
                        <span>Next, drag the  <span className="less-bold">Add To QuickRegistry List</span> button to your bookmarks bar, which can be found at the top of this page.</span>
                      </span>
                    </div>
                    <div className="how-it-works-container">
                      <span className="mulitple-list-number">3</span>
                      <span className="last-steps-details-container">
                        <span className="last-step-heading">Use the Button to Add Gifts to Your List</span> <br />
                        <span>Now, try it out! Go to any store you love, find a product you want, and then click the button. Voila! It's on your gift list.</span>
                      </span>
                    </div>
                  </div> 
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AddGiftsPage;