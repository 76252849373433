/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { getRegistryCredentials } from "../../utils/registryUtils";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import MobileHomepage from "../../components/sections/MobileHomepage";
import WelcomeSection from "../../components/sections/WelcomeSection";
import StoreItemsSection from "../../components/sections/StoreItemsSection";
import BrandSection from "../../components/sections/BrandSection";
import CashGiftSection from "../../components/sections/CashGiftSection";
import CreateWishlistSection from "../../components/sections/CreateWishlistSection";
import ChecklistSection from "../../components/sections/ChecklistSection";
import FaqSection from "../../components/sections/FaqSection";
import GuideSection from "../../components/sections/GuideSection";
import BabyRegistrySection from "../../components/sections/BabyRegistrySection";
import DownloadAppSection from "../../components/sections/DownloadAppSection";
import RequestPasswordModal from "../../components/dashboard/modal/RequestPasswordModal";
import Footer from "../../components/common/Footer";
import api from "../../services/api";

const MainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fromMobileApp } = getRegistryCredentials();
  const queryParams = new URLSearchParams(location.search);
  const requestId = queryParams.get("passwordRequestId");
  const [requestInfo, setRequestInfo] = useState(null);
  const [fetchedRequestInfo, setFetchedRequestInfo] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("userNotFound") === "true") {
      showToast("Account not found", "error");
    } else if (searchParams.get("alreadyVerified") === "true") {
      showToast("Email already verified");
    } else if (searchParams.get("invalidToken") === "true") {
      showToast("Activation code invalid", "error");
    } else if (searchParams.get("emailVerification") === "true") {
      showToast("Your Account has been verified successfully", "success");
    } else if (searchParams.get("someErrorOccurred") === "true") {
      showToast("Some error occurred", "error");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var btn = $("#button");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("showbtn");
      } else {
        btn.removeClass("showbtn");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }, []);

  window.handleSharedText = async (data) => {
    localStorage.setItem("productUrl", data.url);
    localStorage.setItem("rawHTML", data.response);
    navigate("/add-gift-modal");
  };

  useEffect(() => {
    if (fetchedRequestInfo && requestId) {
      $("#password-request").modal("show");
    }
  }, [fetchedRequestInfo, requestId]);
  
  useEffect(() => {
    const fetchRequestInfo = async () => {
      try {
        const data = await api.getRequestPasswordInfo(requestId);
        if (data.message === "Password already sent.") {
          showToast("Password has already been sent successfully");
        } else {
          setRequestInfo(data);
          setFetchedRequestInfo(true);
        }
      } catch (error) {
        showToast("Error occured. Please try again");
        console.error("Error fetching request info:", error);
      }
    };

    if (requestId) {
      fetchRequestInfo();
    }
  }, [requestId]);

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        {!fromMobileApp && (
          <header className="header">
            <Header />
            <Navbar />
          </header>
        )}
        {fromMobileApp && <MobileHomepage />}
        {!fromMobileApp && (
          <>
            <WelcomeSection />
            <StoreItemsSection />
            <BrandSection />
            <CashGiftSection />
            <CreateWishlistSection />
            <ChecklistSection />
            <FaqSection />
            <GuideSection />
            <BabyRegistrySection />
            <DownloadAppSection />
          </>
        )}
        {!fromMobileApp && (
          <Footer />
        )}

        <RequestPasswordModal 
          requestInfo={requestInfo}
        />

        <Link id="button">
          <i className="la la-angle-up"></i>
        </Link>
      </div>
    </div>
  );
};

export default MainPage;
